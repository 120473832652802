import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = ({ items }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="bg-primary/[0.2] md:pt-5 pt-2">
      <div className="grid md:grid-cols-3 mt-10 lg:px-32 px-5 pb-5">
        <div className="">
          <Link to="#" smooth onClick={scrollToTop}>
            <img
              className="w-[70%] mx-auto cursor-pointer"
              src="/Assets/Logo.png"
              alt="logo"
            />
          </Link>
          <p className=" my-5 leading-snug mx-auto font-medium text-base text-justify">
            Monaa Yoga: Where Every Breath Inspires a Healthier You. With 37
            years of yoga mastery, we invite you to embark on a journey that
            transcends physical postures — it's a path to revitalize your mind,
            body, and spirit. Join us, experience the power of yoga, and unlock
            a healthier, happier you.
          </p>
        </div>
        <div className="md:ml-[40%] md:block hidden">
          <h1 className="text-xl font-medium mt-20 ">Quicklinks</h1>
          {items.map((item) => (
            <Link to={item.link} smooth onClick={scrollToTop}>
              <li className="list-none relative group py-1 cursor-pointer">
                {item.text}
                <span className="absolute bottom-1 left-0 w-0 h-0.5 bg-primary transition-all group-hover:w-[20%]"></span>
              </li>
            </Link>
          ))}
        </div>
        <div className="md:ml-[25%] md:text-left text-center font-gil">
          <h1 className="md:mt-20 mt-10  font-medium text-xl">Contact Us</h1>

          <p className="leading-loose">
            <a href="tel:+91 8383078277">
              <span>8383078277</span>
            </a>{" "}
          </p>

          <p className="leading-loose">
            Kochi, Kerela
          </p>
        </div>

        <div className="block md:hidden text-center mt-10">
          <h1 className="text-xl font-medium mt-5">Quicklinks</h1>
          {items.map((item) => (
            <Link to={item.link} smooth onClick={scrollToTop}>
              <li className="list-none relative group py-1 cursor-pointer">
                {item.text}
                <span className="absolute bottom-1 left-0 w-0 h-0.5 bg-secondary transition-all group-hover:w-[20%]"></span>
              </li>
            </Link>
          ))}
        </div>
      </div>

      <div className="bg-primary/[0.70] md:flex justify-around py-1 items-center">
        <div>
          <h1 className="md:py-2 py-5 text-sm font-bold text-center md:text-justify">
            All Right Reserved | Monaa Yoga
          </h1>
        </div>
        {" "}
        <div className="flex justify-center md:py-0 pb-3 items-center">
          {/* <a href="/">
            <FaFacebookF className="w-8 h-8 text-primary bg-white rounded-full p-1.5 mx-2 hover:bg-primary hover:text-white border-2 border-white cursor-pointer" />
          </a> */}

          <a href="https://www.instagram.com/monaayoga/">
            <AiFillInstagram className="w-8 h-8 text-primary bg-white rounded-full p-1.5 mx-2 hover:bg-primary hover:text-white border-2 border-white cursor-pointer" />
          </a>

          <a href="https://www.youtube.com/@monaayoga">
            <BsYoutube className="w-8 h-8 text-primary bg-white rounded-full p-1.5 mx-2 hover:bg-primary hover:text-white border-2 border-white cursor-pointer" />
          </a>

          {/* <a href="/">
            <AiFillLinkedin className="w-8 h-8 text-secondary bg-white rounded-full p-1.5 mx-2 hover:bg-secondary hover:text-white border-2 border-white cursor-pointer" />
          </a> */}
        </div>{" "}
        
      </div>
    </section>
  );
};

Footer.defaultProps = {
  items: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Workshop",
      link: "/workshop",
    },
    {
      text: "About Us",
      link: "/about",
    },
    {
      text: "Learn Yoga",
      link: "/yoga",
    },
    {
      text: "Courses",
      link: "/courses",
    },
    {
      text: "Contact Us",
      link: "/contact",
    },
  ],
};

export default Footer;
