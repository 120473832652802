import React from "react";
import { Coming } from "../components";
import { useQuery } from "react-query";
import { GetALLCourses } from "../endpoints/courses";
import { LoadingOverlay } from "@mantine/core";
import { CourseCard } from "../components/Courses/CourseCard";

const Courses = () => {
  const allCoursesQuery = useQuery({
    queryFn: GetALLCourses,
    queryKey: ["all-courses"],
  });
  return (
    <div>
      {/* <Coming /> */}
      <LoadingOverlay
        visible={allCoursesQuery.isLoading}
        zIndex={100000}
        overlayProps={{ radius: "sm", blur: 1 }}
      />
      <div
        className="h-screen pt-20 overflow-auto"
        style={{
          backgroundImage: `url('/Assets/Group18.png')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="p-10 flex gap-5 flex-wrap justify-center">
          {allCoursesQuery?.data?.data.map((item, index) => (
            <CourseCard
              key={`${index}-course-card`}
              name={item?.name}
              description={item?.description}
              price={item?.price}
              thumbnail={item?.thumbnail}
              id={item?._id}
              bookCoverPageUrl={item?.bookCoverPageUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Courses;
